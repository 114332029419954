.searchPageLandingSection {
  background-image: url("../images/HomeLandingSection.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


}
.searchPageBackground {
  background-image: url("../images/othersBG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: hsla(0,0%,100%,.6);
  background-blend-mode: lighten;
}


.SearchPageLandingSectionTitle {
  font-family: Bebas Neue;
  font-style: normal;
  font-size: 56px;
  /* line-height: 80px; */
  font-weight: 100;
 
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-transform: capitalize;
}

.SearchPageLandingSectionSubTitle {
 
  font-size: 24px;
  font-family: "SF UI Display";
  color: #FFFFFF;
}
.SearchPageRow{
  padding-top: 15%;
}


@media  (max-width: 1024px) {
  
  .SearchPageLandingSectionTitle {
    font-size: 20px;
  }
  .SearchPageLandingSectionSubTitle {
 
    font-size: 12px;
   
  }
  .SearchPageRow{
    padding-top: 30%;
  }
  
}