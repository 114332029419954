.ThankyouMainSection{
    background-image: url("../images/HomeLandingSection.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 300px;
    max-height: 300px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.selectAccountTypeMainSection{
    background-image: url("../images/othersBG.png");
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 0px;
   
    padding: 60px 0px;
    margin-top: 100px;
}
.IAmAUser{
    background-image: url("../images/IAmAUser.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 300px;
    max-height: 300px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.IAmACoach{
    background-image: url("../images/IAmACoach.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 300px;
    max-height: 300px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.IAmAUserHeader1{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
}
.IAmAUserHeader2{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;

}
.IAmACoachHeader1{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
}
.IAmACoachHeader2{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}
.WhatKindOfAccountYouWant{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #222222;
}
.ThankyouForJoiningUs{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #222222;
}
.btnProfileSettings {
    /* width: 100%; */
    color: white;
    margin-left: 10px;
    background-color: #D12316;
    border-radius: 4px;
    padding: 10px 15px !important;
    /* float: right; */
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 29px; */
}
.btnProfileSettings:hover {
    color: white !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    transition: all 0.4s ease 0s!important;
  }

  @media  (max-width: 1024px) {
    .ThankyouForJoiningUs{
        font-family: SF UI Display;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #222222;
    }
    .btnProfileSettings {
        /* width: 100%; */
        color: white;
        margin-left: 10px;
        background-color: #D12316;
        border-radius: 4px;
        padding: 10px 10px !important;
        /* float: right; */
        font-family: SF UI Display;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        /* line-height: 29px; */
    }
    .selectAccountTypeMainSection{
        background-image:unset;
        padding: 0px;
    }
  }

