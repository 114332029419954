.loginToRateLink {
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #0B3790;
  
  text-decoration-color: #0B3790;
  font-size: 0.8em;
  display: inline-block;
  cursor: pointer;
}
.ant-popover-title{
  text-align: center;
}
.subscriptionLevel{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.spanAdminSettings{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.website-button {
  background-color: white;
  width: 100%;
  color: #4A51F4;
}
.facebook-button {
  background-color: white;
  width: 100%;

  color: #4A51F4;
}
.linkedin-button {
  background-color: white;
  width: 100%;
  color: #374EC3;
}
.website-button:hover {
 
  color: #000053 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.facebook-button:hover {
  color: #000053 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.linkedin-button:hover {
  color: #000053 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.yourStoryVideoTitle{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #222222;
}
.carousel.carousel-slider{
  box-shadow: 0 0 10px black;
  border-radius: 5px;
}

.carousel.carousel-slider .control-arrow{
  opacity: 1 !important;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 18px solid transparent !important;
  border-bottom: 18px solid transparent !important;
  content: '';
}
.carousel .control-next.control-arrow:before {
  border-left: 18px solid red !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 18px solid red !important;
}
.carousel-status{
  font-size: 12px !important;
  font-weight: 700 !important;
  color: red !important;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  /* margin: 0 40px 40px; */
  /* border: 0; */
  margin: 0px 0px 0px !important;

}
.control-dots{
  margin: -10px;
}

.thumbs-wrapper.axis-vertical{
  margin: 0px !important;
}
.tainingvideos{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #222222;
}

.btnRateThisCoach{
  color: white;
  background-color: #D12316 !important;
  border-radius: 4px !important;
  padding: 10px 15px !important;
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  height: unset !important;
  margin: 0px 10px;
}
.btnRateThisCoach:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.enterYourEmail{
  margin-right: 20px;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}
.btnContactMeDirectlySendMessage{
  color: white;
  background-color: #D12316 !important;
  border-radius: 4px !important;
  padding: 10px 30px !important;
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  height: unset !important;
}
.btnContactMeDirectlySendMessage:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
#main-section {
    background-image: url("../images/othersBG.png");
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
    margin-top: 100px;
    
  }
  .btnContactMeDirectly{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #0B3790;
    border-color: #0B3790;
    border-radius: 4px;
    margin-right: 20px;
  }
  .btnContactMeDirectly:hover {
    color: white !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
    }
  .btnBadgePill{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #0B3790;
    background: #ffff;
    color: #254D9F;
    border-radius: 10rem;
    margin: 0px 10px 10px 0px;
  }
  .btnBadgePill:hover {
    color: #254D9F;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
    }
  
.username{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #222222;
}
.useremail{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 64px;
  
}
.commenteeName{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #141618;
}
.commenteeComment{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #222222;
}
.btnRateThisCoach{
  color: white;
  background-color: #D12316;
  border-radius: 4px;
  padding: 15px 30px !important;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
}
.btnRateThisCoach:hover {
  color:white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1)!important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;;
  }
  .btnUpgrade{
    color: white;
    background-color: #D12316;
    border-radius: 4px;
    padding: 15px 30px !important;
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  .btnUpgrade:hover {
    color:white !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1)!important;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    transition: all 0.4s ease 0s!important;;
    }
.btnClaimProfile{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #0B3790;
  color: #ffffff;
  border-radius: 4px;
  line-height: 17px;
  padding: 15px;
  width: 100%;
}
.btnClaimProfile:hover {
  color:white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1)!important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;;
  }
.aboutStart{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #222222;
}
.about{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  color: #222222;
}
.aboutme{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #555555;
}
.clientFeedBack{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 64px;
  color: #222222;
}
.profileRightSectionRow{
  background-color:#f3f3f9;
  margin-left:0px;
  border-radius:4px;
  height:100%;
}

.profileRightSectionRow{
  
  margin-left:-15px;
 
}
  
