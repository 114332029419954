.paddingOnLargeScreenOnly{
  padding-top: 20px;
  padding-bottom: 20px;
}
.imgArrowDown{
  
  content:url("../images/arrowdown_red.gif");
  height: 120px;
  
}
.btnForgotPasswordSendCode{
  color: white !important;
  background-color: #D12316 !important;
  border-radius: 4px !important;
  padding: 10px 30px !important;
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  height: unset !important;
}
.btnForgotPasswordSendCode:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.RemovePaddingFifty{
  padding-top: 50px;
  padding-bottom: 30px;
}
section#signup-section {
  background-image: url("../images/othersBG.png");
  background-color: rgba(255,255,255,0.6);
  background-blend-mode: lighten;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-top: 0px;
 
  padding: 60px 0px;
  margin-top: 100px;
}
div.signup_box {
  width: 400px;
  font-family: 'Roboto', sans-serif;
}

div.img_container {
  height: auto;
  /* width: 480px; */
  background-image: url("../images/signUp_side_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signUpBtn {
  border-radius: 0;
}



/* Facebook */
.loginBtn--facebook {
 
  background-image: linear-gradient(#4C69BA, #3B55A0);
  font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 #354C8C;
  background: url('../images/btnFacebook.svg') no-repeat;
  height: 40px;
    width: 100%;
    border: 0px;
    background-position: center;
    border-radius: 4px;
    cursor: pointer;
    background-size: cover;
}

.loginBtn--google {
  background-image: linear-gradient(#4C69BA, #3B55A0);
  font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 #354C8C;
  background: url('../images/btnGoogle.svg') no-repeat;
  height: 40px;
    width: 100%;
    border: 0px;
    background-position: center;
    border-radius: 4px;
    cursor: pointer;
    background-size: cover;
}
.socialMediaSignUp{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;


  color: #222222;
}
.useYourExistingAccount{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #555555;
}
.txbLabel{
  font-family: SF UI Display;
font-style: normal;
font-weight: 500;
font-size: 16px;
color: #222222;
}
.txbSignup{
  background: #F9FAFD;
border: 1px solid #BECEEB;
box-sizing: border-box;
border-radius: 4px;
}
.btnSignup{
  color: white;
  background-color: #D12316;
  border-radius: 4px;
  padding: 10px 30px !important;
  float: right;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.btnSignup:hover {
   color:white !important; 
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
  }
.back{
  font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #A1ABBC;
    padding-top: 10px;
}
.PaddingRemoveOnSmallScreen{
  padding-top: 20px;
}
@media  (max-width:1024px) {

  .paddingOnLargeScreenOnly{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .socialMediaSignUp{

    font-size: 20px;

  }
  section#signup-section {
    background-image:unset;
    padding: 0px 0px;
    
  }
  .RemovePaddingFifty{
    padding-top: 0px;
   
  }
 
  .loginBtn--facebook {

    margin-bottom: 20px;;
  }
  .hrNotVisibleOnSmallScreen{
    display: none;
  }
  .PaddingRemoveOnSmallScreen{
    padding-top: 0px;
  }
}