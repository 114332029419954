

.featuresSpan{
 
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #555555;
  overflow: hidden;
}
.antListPlanCard .ant-list-item{
  text-align: left !important;
  border: 0px !important;


}
.ant-list-pagination {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center !important;
}
.btnSubscribeForPlan{
  color: white;
  background-color: #D12316 !important;
  border-radius: 4px !important;
  padding: 10px 30px !important;
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  height: unset !important;
}
.btnSubscribeForPlan:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.ant-switch {
  border-radius: 100px ;
  padding: 0px 10px;
}
.ant-switch::after {
  top: 8px !important;

}
.FourMonthSubscription{
  margin-right: 20px;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}
.ChooseOneLeaderBoardAdvertisingPackageBelow{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  color: #222222;
}
.plan-features>li{
  position: relative;
  text-align: left;
  margin: 20px 0px;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #555555;
}
.PlanCardTitle{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #FFFFFF;
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;

}
.PlanCardCharges{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
}
.PlanCardChargesPrefix{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #222222;
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
}
.PlanDescription{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #222222;
 
  display: inline-block;
}
.btnPlansSelected{
  color: white;
  background-color: #D12316;
  border-radius: 4px;
  padding: 10px 15px !important;
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
}
.btnPlansSelected:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.btnGetStarted{
  color: white;
  background-color: #D12316;
  border-radius: 4px;
  padding: 10px 30px !important;
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
}
.btnGetStarted:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.btnPlansNotSelected{
  cursor: pointer;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

div.plan-card:hover {
  /* transform: scale(1.05); */
  z-index: 1090;
}

div.plan-card {
  background-color: #ffffff;
  min-height: 750px;
}

h3.plan-card-title {
  color: #ffffff;
  background-color: #1E4189;
  padding: 1em;
}

.prefix {
  font-size: 0.8rem;
}

.oldPrice {
  color: red;
  text-decoration: line-through;
}

.newPrice {
  color: red;
}

@media  (max-width: 1024px) {
  .ChooseOneLeaderBoardAdvertisingPackageBelow {
    font-size: 16px;
  }
  .PlanCardTitle{
    
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
  
  }
}