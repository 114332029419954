.container-fluid.ad-container {
    /* background-color: white; */
}


.container-fluid.ad-container .card {
    /* background-color: lightgray; */
    border-radius: 0;
    margin: 2em;
}

.container-fluid.ad-container .card h6 {
    line-height: 6em;
    text-align: center;
    margin-bottom: 0;
}
#outer img{
    width: 100% !important;
    max-width: 728px !important;
}


