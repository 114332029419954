.featuredProfileCardAboutMe{
  text-align: left;
 
  padding: 0px;
}
.featured-card-container{
  padding:100px 0px;
}
.ProfileCardNameOuterdiv{
    overflow: hidden;
    text-overflow: ellipsis;
    height: 62px;
    padding-top: 10px;
}
.ProfileCardName{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
  overflow-wrap: break-word;
  text-align: left;

}
.ProfileCardPhone{

  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}
.featuredProfileCardContainer:nth-child(even) {
  padding: 1em 0em 0em 0.5em;
  width: 50%;
  /* float: left; */
}

.featuredProfileCardContainer:nth-child(odd) {
  padding: 1em 0.5em 0em 0em;
  width: 50%;
  /* float: left; */
}

.featuredProfileCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 0.5em; */
  background: #fff;
  padding: 10px;
  max-height: 180px;
  min-height: 180px;
}

.profileImageContainer {
  position: relative;

}

.profileImageContainer > img {
  width: 100%;
  height: auto;
  border: 1px solid #EBEDF2;
}

span.infoIcon {
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #F3F5FB;
  width: 1.5em;
  height: 1.5em;
  font: italic bold 1em/1.5em Georgia, serif;
  text-align: center;
  border-radius: 50%;
  font-size: x-large;
  position: absolute;
  top: .1em;
  left: .1em;
}

.star-block {
  display: inline-block;
  margin: 0 1em;
  background: #EEF6FC;
  border-radius: 2em;
  padding: 0.4em 0.4em;
  font-size: 15px;
  color: #254D9F;
}



@media (max-width:1320px) {

  .featuredProfileCardAboutMe{
    text-align: center;
   
    padding: 0px;
  }
  .ProfileCardNameOuterdiv{
    overflow: hidden;
    text-overflow: ellipsis;
    height: 28px;
    padding-top: 10px;
}
  .featuredProfileCard {
    padding: 10px;
    max-height: 260px;
    min-height: 260px;
  }
  span.infoIcon {
    top: 5px;
    left: 36px;
    width: 10px;
    height: 10px;
  }
  .ProfileCardName{
    text-align: center;
    font-size: 14px;
  }
  .ProfileCardPhone{
    text-align: center;
    font-size: 14px;
  }
  .star-block {
 
    font-size: 10px;
   
  }
  .rating{
    display: none;
  }
  
}
