.toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0;
  color: #222222;
  transition: .2s;
  font-weight: bold;
}
.toggler--is-active {
  color: #000000;
}

/* slide/switch */
.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}
.toggle input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.switch {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  cursor: pointer;
  background-color: #254D9F;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 24px;
}
.switch:before {
  position: absolute;
  bottom: 4px; left: 4px;
  content: "";
  width: 16px;
  height: 16px;
  background-color: #EBEBEB;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .switch {
  background-color: #254D9F;
}
input:focus + .switch {
  box-shadow: 0 0 1px #254D9F;
}
input:checked + .switch:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}