.profile-picture {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
    width:100%;
    height:auto;
    display:block;
    /* div height to be the same as width*/
    padding-top:100%; 

    /* make it a circle */
    border-radius:50%;

    /* Centering on image`s center*/
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;

    /* it makes the clue thing, takes smaller dimension to fill div */
    background-size: cover;
    border: 1px solid lightgray;
}
.profile-picture_noImage {
  background-image:url('../images/no_image.png');
    
}
@media  (max-width:1024px) {
  
    .profile-picture {
      max-width: 50%;
      width:50%;
      height:auto;
      padding-top:50%; 
     margin: auto;
     margin-top: 10px;
     margin-bottom: 10px;
     
  }
}