.featured{
  border: 1px solid yellow;
}
.coachcard{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #FFFFFF;
  padding: 40px;

}
.CoachCardName{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #222222;
  padding:20px 0px;
  display: inline-block;
  line-height: 50px;
}
.CoachCardAboutMe{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #555555;
}
.CoachOffers{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #222222;
  padding:20px 0px;
  display: inline-block;
}
.btnLearnMore{
  margin-bottom: 10px;
  background-color: #254D9F;
    width: 100%;
    color: white;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 7px 17px !important;
    transition: all 0.4s ease 0s;
}
.btnLearnMore:hover {
   color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
  }

  @media  (max-width:1024px) {
  
    .coachcard{
  
      padding: 0px 40px;
    
    }
  }

 