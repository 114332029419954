li.pill {
    list-style: none;
    display: inline-block;
    padding: 0.5em;
    border-radius: 2em;
    border: 1px solid #fff;
    position: relative;
    margin: 0.5em;
}

li.pill.selected {
    background: #fff;
    color: #0B3790;
}