
.ant-switch {
    margin: 0;
    padding: 10px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 44px;
    height: 40px;
    line-height: 20px;
    vertical-align: middle;
    background-color: #0B3790;
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    -webkit-transition: all 0.36s;
    transition: all 0.36s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ant-switch-checked {
    background-color: #D12316;
}

.ant-switch-inner {
    display: block;
    margin-right: 6px;
    margin-left: 24px;
    color: #fff;
    font-size: 16px;
}
.ant-switch::after {
    top: 10px;
    width: 22px;
    height: 22px;
   
}

