.input-group.search {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    height: 70px;
    padding: 0px;
}
.input-group-prepend {
    padding: 0px 10px;
    border-right: 1px solid #39525e;
}
.txbSearchBox{
    height: 100%;
}
.txbSearchBox::placeholder{
    color: white;
    font-size: 18px;
}
.input-group-append{
    margin: 5px;
}
.btnSearch{
    padding: 14px 50px !important;
    background-color: #D12316;
    color: white;
    border-radius: 4px !important;
    font-size: 20px;
}
.btnSearch:hover {
    color:white !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1)!important;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    transition: all 0.4s ease 0s!important;;
    }
.input-group.search input {
    background: transparent;
    border: none;
    color: white;
    outline-width: 0;
}
.txbSearchBox:focus {
    
    outline-width: 0;
}

.input-group.search .input-group-text {
    background: none;
    border: none;
}


@media  (max-width: 1024px) {
  
   
    .btnSearch{
        padding: 8px 14px !important;
        background-color: #D12316;
        color: white;
        border-radius: 4px !important;
        font-size: 14px;
        height: 55px;
    }
    
  }

