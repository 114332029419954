.category-card {
    height: 360px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
}
.category-card-name-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;   
}
.category-card-name-wrapper > h5 {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 64px;
    letter-spacing: 0.05em;
}


