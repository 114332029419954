.ChangePassword{
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 0px;
}
.Level6Features{
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 0px;
}
.Level5Features{
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 0px;
}
.Level4Features{
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0px;
}
.Level3Features{
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0px;
}
.Level2Features{
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0px;
}
.profileFormHeadings{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.ant-list-item{
  text-align: unset !important;
  border-bottom: 1px solid #e8e8e8 !important;


}

.authoriztionFailed{
  cursor:not-allowed;
  

}
#main-section {
    background-image: url("../images/othersBG.png");
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
    margin-top: 100px;
    
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 150px;
}
.btnAddTrainingVideo{
  color: white;
 
  background-color: #D12316;
  border-radius: 4px;
  
  
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  
  line-height: 29px;
  width: 100%;
}
.btnAddTrainingVideo:hover {
    color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
  }
.btnSaveProfile{
  color: white;
  margin-left: 10px;
  background-color: #D12316;
  border-radius: 4px;
  padding: 10px 30px !important;
  float: right;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.btnSaveProfile:hover {
    color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
  }
  .btnLinkThisProfile{
    background-color: #0B3790 !important;
    color: white !important;
    border-radius: 4px !important;
    padding: 10px 30px !important;
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    height: unset !important;
  }
  .btnLinkThisProfile:hover {
    color: white !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    transition: all 0.4s ease 0s!important;
    }