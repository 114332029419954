.badge-card {
    border: 1px solid rgba(11,55,144,0.25);
    border-radius: 5px;
    display: inline-block;
    font-size: 0.8em;
    width: 8em;
    padding: 0;
    margin: 0 1em 1em 0;
}

.badge-image {
    min-height: 4em;
}

.badge-card label {
    background: rgba(11,55,144,0.25);
    display: inline-block;
    width: 100%;
    margin: 0;
    text-align: center;
}

.badge-check {
    font-size: 2em;
    color: #0B3790;
}