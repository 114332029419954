.category-title{
  font-family: SF UI Display;
font-style: normal;
font-weight: normal;
font-size: 36px;
text-transform: uppercase;

color: #FFFFFF;
}
.sub-category-title{
  font-family: SF UI Display;
font-style: normal;
font-weight: normal;
font-size: 10px;
text-transform: uppercase;

color: #FFFFFF;
margin-bottom: 0px;
}
.sub-category-link{
  align-items: center;
  flex: 0 1 28%;
  border: 1px solid white;
  border-radius: 30px;
  padding: 10px 10px;
  margin: 10px;
  text-decoration: none;
  opacity: 0.4;
  justify-content: center;

}
.subCategoryContainer{
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}

.category-card-cover {
  background-color: white;
}

ul.featuredCarrdsList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* box-sizing: border-box; */
}

.tags-container {
  /* background: linear-gradient(rgba(76,105,186,1), rgba(59,85,160,0.3)); */
  padding-top: 150px;
}

.tags-container ul {
  padding: 0;
  margin: 0;
}

.background-container {
  background: linear-gradient(to right, rgba(76,105,186,1), rgba(59,85,160,0.3));
  padding: 5em 0;
}


@media (min-width:801px) {
  .container.flex {
    display: flex;
  }


  ul.featuredCarrdsList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (max-width:1024px) {
  .tags-container {
    /* background: linear-gradient(rgba(76,105,186,1), rgba(59,85,160,0.3)); */
    padding-top: 50px;
  }
  .category-title{
   
  font-size: 30px;
  
  }
  .sub-category-link {
    align-items: center;
    flex: 0 1 30%;
    border: 1px solid white;
    border-radius: 30px;
    padding: 5px 5px;
    margin: 5px;
    text-decoration: none;
    opacity: 0.4;
    justify-content: center;
}
.sub-category-title {
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 0px;
}
  
}

