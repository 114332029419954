.lightmode{
    color: rgb(37, 77, 159);
    background-color: white;

}
.lightmode .customtoggler>i{
    color: rgb(37, 77, 159)
}
.lightmode .nav-link{
    color: rgb(37, 77, 159) !important;
}
.lightmode .btnSignIn{
    color: rgb(85, 85, 85);
    border-color: rgb(85, 85, 85);
}

.darkmode{
    color: white;
    background-color: transparent;
}

.darkmode .customtoggler>i{
    color: white
}
.darkmode .nav-link{
    color: white !important
}
.darkmode .btnSignIn{
    color: white;
    border-color: white;
}


::-webkit-scrollbar {
width: 15px;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
background: #0070af;
}

::-webkit-scrollbar-thumb:hover {
background: #0070af;
}

body ::-webkit-scrollbar {
    width: 5px;
    }

.navbar {
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    top: 0px;
    z-index: 1000;
    position: absolute;
    width: 100%;
  

   
}
.nav-link {
    color: white ;
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
  }

.btnSignIn{
    margin-left: 10px;
    color: white;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 7px 17px !important;
    transition: all 0.4s ease 0s;
}
.btnSignIn:hover {
    
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    transition: all 0.4s ease 0s!important;
    }
.btnCreateProfile{
    color: white;
    margin-left: 10px;
    background-color: #D12316;
    border-radius: 4px;
    padding: 7px 17px !important;
    transition: all 0.4s ease 0s;
}
.btnCreateProfile:hover {
    color: white !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
    transition: all 0.4s ease 0s!important;
    }
.userIconImg{
    padding-left: 0px;
    margin-top: -1px;
}

.navbar-collapse.collapse.show .nav-link{
    color: white !important;
}
.navbar-collapse.collapse.show .btnSignIn{
    color: white !important;
    border-color: white !important;
}

.navbar-collapse.collapse.show .btnSignIn{
    margin-left: 15px;
    margin-bottom: 15px;
}
.navbar-collapse.collapse.show .btnCreateProfile{
    margin-bottom: 15px;
}
.navbarButtons{
    margin-left: auto;
 
}

.dropdown-menu{
    padding: 0 0;
    border-radius: 0px;
    }
    .dropdown-item:active {
    color: #212529;
    text-decoration: none;
    background-color: transparent;
    }

@media  (max-width: 1024px) {

    .lightmode{
        color: white;
        background-color: white;
    
    }
    .lightmode .customtoggler>i{
        color: rgb(37, 77, 159)
    }
    .lightmode .nav-link{
        color: white !important;
    }
    .lightmode .btnSignIn{
        color: white;
        border-color: white;
    }

    .collapse:not(.show) {
        display: block;
    }
    .navbar-collapse.collapse.show .btnCreateProfile {
        margin-left: 15px;
        margin-bottom: 15px;
    }
    .navbarButtons{
        padding-top: 5px;
        padding-left: 10px;
    }
    .customtoggler {
        padding: 0px;
        margin-right: 32px;
        color: white;
        border-color: white;
        background:transparent;
        border: 0px;
        
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url('../images/toggler.png');
        width: 50px;
        height: 30px;
    }

    .dropdown-item{
        color: white;
    }
    .btnCreateProfile{
        width: 32vw;
        padding: 10px 5px !important;
        margin: 5px;
    }
    .btnSignIn{
        width: 25vw;
            padding: 10px 0px !important;
            margin: 5px;
    }
    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 15px;
    }
    .btnSearch{
        height: 40px;
    }
    .input-group.search {
    
        height: unset; 
        
    }
    .dropdown-menu.show{
        max-height: 170px;
    overflow: auto;
    background-color: #bf1f11;
    }
.navbar-nav {
    
  
    max-height: 100vh;
    overflow: auto;
    background-color: #077ab6;
    margin-right: 32px;
   
}
    .navbarButtons{
        margin-left: auto;
        background: #077ab6;
        margin-right: 32px;
    }
    .navbar-brand {
        margin-left: 15px !important;
     
  }
  .txbSearchBox::placeholder { /* WebKit, Blink, Edge */
    
    font-size: 12px;
}
.notactive{
    display: none;
}
.active{
    display: block;
}

}
@media  (min-width: 1025px) {

    .customtoggler{
        display: none;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        max-height: 355px;
        overflow-y: auto;
    }
  
}
@media (min-width: 1500px){
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
}
}

