
@media (max-width: 768px)
{
  .form-control {
    margin: 5px 0px 10px 0px !important;
    
  }
}

.btn:disabled {
  cursor: not-allowed;
}
.empty_message{
  border: 1px solid #1f2084;
  background-color: #e9e9f2;
  color: #1f2084;
  padding: 18px;
  line-height: 21px;
  text-align: center;
  margin: 50px 0px 100px 0px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #01396a;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
@media (max-width: 768px)
{
  .container-fluid {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#navbar {
  z-index: 9999;
  /* position: absolute; */
  width: 100vw;
}

#mainbody {
  padding-top: 0px;
  min-height: 94vh;
}

#footer {
  height: 6.75vh;
}

div.background {
  height: auto;
}

.profile-cta > button {
  padding: 0.4em 1em;
}

.profile-cta > button.btn-primary {
  background: #0B3790;
  border-color: #0B3790;
  border-radius: 0.3em;
}

.profile-cta > button.badge-pill {
  border: 1px solid #0B3790;
  background: #ffff;
  color: #0B3790;
}

.basic-details {
  background: #F3F3F9;
  border-radius: 4px
}

.basic-details .username{
  background: #DCDBEF;
  font-weight: bold;
  padding: 1em;
}

.basic-details .ratings {
  background: #E7E8F4;
  font-size: 0.8em;
}

.basic-details .ratings .star-block {
  display: inline-block;
  margin: 0 1em;
  background: #F9F9FF;
  border-radius: 2em;
  padding: 0.4em 0.4em;
}

.basic-details .badges-container {
  padding-top: 3em;
}

.basic-details .chart-container {
  padding-top: 3em;
  padding-bottom: 3em;
}



.comment-section .comment-card {
  padding: 1em;
}

.comment-section .comment-card .comment-card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.comment-section .comment-card .comment-card-header img{
  height: 5em;
  width: auto;
}

.youtube-container {
  position: relative;
  margin: 4em 0;
}

.youtube-container .youtube-play-button {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  height: 4em;
  width: 4em;
  border-radius: 2em;
  background: #F9F9FF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube-container img {
  width: 100%;
}


.profile-extra-section {
  padding: 0 0 0 1em;
  border-left: 10px solid transparent;
}

.details-block {
  border: 1px solid #BECEEB;
  border-radius: 4px;
}

.profile-extra-section .profile-extra-container {
  background: #F3F3F9;
  height: 100%;
}

.profile-extra-section .profile-extra-container .claim-button {
  background: #0B3790;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin: 0 0 2em 0;
}

.share-button-container button, .share-button-container a {
  margin: 2em 0;
  border: 1px solid rgba(11,55,144,0.5);
  background: #fff;
  color: #0B3790;
  padding: 0.5em;
  display: inline-block;
  text-decoration: none;
}

div.about {
 background-color: #ffffff;
 border-radius: 4px;
}

.about-section-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 1.4em;
  font-weight: bold;
  color: #222222;
}

.feedback-section-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  color: #222222;
}

a.plan-button {
  cursor: pointer !important;
  text-decoration: underline !important;
}

a.plan-button.btn-danger {
  color: #fff !important;
  text-decoration: none !important;
}
.plan-card {
  min-height: 500px;
}

.planDesc {
  font-size: 0.7rem;
}

.plan-features {
  list-style: none;
  font-size: 0.85rem;
}


.payment-selector-container {
  min-height: 100px;
  padding: 2.5em;
}

.payment-selector {
  border-top: 1px solid rgba(11,55,144,0.25);
  border-bottom: 1px solid rgba(11,55,144,0.25);
  padding: 1em 0;
  font-size: 0.8em;
}


.toggle-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.comment-section h3 {
  font-size: 16px;
}

form.rate-coach-container h4, form.rate-coach-container h5 {
  text-align: center;
}

form.rate-coach-container h4 {
  font-size: 1.2em;
}

form.rate-coach-container h5 {
  font-size: 1em;
  font-weight: normal;
}


form.rate-coach-container .form-group {
  text-align: center;
}

button.profile-website {
  background: #fff;
  color: #0B3790;
  width: 100%;
}

.user-profile-picture {
  min-height: 170px;
  min-width: 170px;
  max-height: 170px;
  max-width: 170px;
  height: 170px;
  width: 170px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin: auto;
}

.user-profile-picture-on-card {
  min-height: 110px;
  min-width: 110px;
  max-height: 110px;
  max-width: 110px;
  height: 110px;
  width: 110px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #EBEDF2;
  /* margin: auto; */
}

.search-background {
  background: linear-gradient(to right, rgba(76,105,186,0.3), rgba(59,85,160,0.1));
}

.search-background .container-fluid.ad-container {
  background: transparent;
}

