.HomeSection1Img1{
  content:url(../images/HomeSection1Img1.png);
  height: 150px;
}
.HomeSection1Img2{
content:url(../images/HomeSection1Img2.png);
height: 150px;
}
.HomeSection1Img3{
content:url(../images/HomeSection1Img3.png);
height: 150px;
}

.HomeSection1Header{
text-align: center;
color: rgb(0, 0, 0);
font-size: 32px;



}
.HomeSection1Header>b{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.05em;
  font-size: 48px;
  text-transform: capitalize;
  color: #222222;

}
.HomeSection1Subheader{
text-align: center;
color: rgb(0, 0, 0);
font-size: 16px;
margin-top: 20px;


}
.HomeSection1Subheader>b{
color: rgb(0, 0, 0);
font-weight: 700;
text-align: center;
font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;

}
.HomeSection1Text{

margin-top: 15px;
}
.HomeSection1Text>span{
text-align: left;
color: rgb(0, 0, 0);
font-size: 16px;

}



.sendEmailLabels{
margin-right: 20px;
font-family: SF UI Display;
font-style: normal;
font-weight: 500;
font-size: 16px;
color: #222222;
}
.btnSendMessage{
color: white !important;
background-color: #D12316 !important;
border-radius: 4px !important;
padding: 10px 30px !important;

font-family: SF UI Display;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 29px;
height: unset !important;
}
.btnSendMessage:hover {
color: white !important;
text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
transition: all 0.4s ease 0s!important;
}


.youhavesomequestions{
font-family: SF UI Display;
font-style: normal;
font-weight: 500;
font-size: 42px;
line-height: 64px;
color: #FFFFFF;
}
.HomeLandingSectionTitle {
font-family: Bebas Neue;
font-style: normal;
font-size: 56px;
/* line-height: 80px; */
font-weight: 100;

align-items: center;
text-align: center;
letter-spacing: 0.05em;
color: #FFFFFF;
text-transform: capitalize;
}

.HomeLandingSectionSubTitle {

font-size: 24px;
font-family: "SF UI Display";
color: #FFFFFF;
}

section#contact {
display: flex;
align-items: center;
height: 260px;
color: white;
}
.HomeTop {
background-image: url("../images/HomeLandingSection.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));

background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;


}
.CategoryCard_Business {
background-image: url("../images/CategoryCard_Business.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));

background-position: center;
background-repeat: no-repeat;
background-size: cover;

}
.CategoryCard_Life {
background-image: url("../images/CategoryCard_Life.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));

background-position: center;
background-repeat: no-repeat;
background-size: cover;

}
.CategoryCard_Relationship {
background-image: url("../images/CategoryCard_Relationship.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));

background-position: center;
background-repeat: no-repeat;
background-size: cover;

}
.CategoryCard_Health {

background-image: url("../images/CategoryCard_Health.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.CategoryCard_More {

background-image: url("../images/CategoryCard_More.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.CategoryCard_Fitness {
background-image: url("../images/CategoryCard_Fitness.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.CategoryCard_Business2 {
background-image: url("../images/CategoryCard_Business2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.CategoryCard_Life2 {
background-image: url("../images/CategoryCard_Life2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.CategoryCard_Relationship2 {
background-image: url("../images/CategoryCard_Relationship2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.CategoryCard_Health2 {
background-image: url("../images/CategoryCard_Health2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.CategoryCard_Fitness2 {
background-image: url("../images/CategoryCard_Fitness2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.CategoryCard_More2 {
background-image: url("../images/CategoryCard_More2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.Homefooter{
background-image: url("../images/FooterBG.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.choosecoaching{
font-family: SF UI Display;
font-style: normal;
font-weight: normal;
text-align: center;
letter-spacing: 0.05em;
font-size: 48px;
text-transform: capitalize;
color: #222222;
}
.HomePageRow{
padding-top: 15%;
}

@media  (max-width: 1024px) {

.HomePageRow{
  padding-top: 50%;
}
.HomeLandingSectionTitle {
  font-size: 20px;
}
.HomeLandingSectionSubTitle {

  font-size: 12px;
  
}
.youhavesomequestions{
  font-size: 24px;
  text-align: center;
}

.HomeSection1Header>b{
  font-size: 24px;
}
.choosecoaching{
  font-size: 24px;
}
.CategoryCard_Business {
  background-image: url("../images/CategoryCard_Business3.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
}
.CategoryCard_Life {
  background-image: url("../images/CategoryCard_Life3.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.CategoryCard_Relationship {
  background-image: url("../images/CategoryCard_Relationship3.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.CategoryCard_Health {
  
  background-image: url("../images/CategoryCard_Health3.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.CategoryCard_Fitness {
  background-image: url("../images/CategoryCard_Fitness3.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar-nav {
  padding-left: 100px;
}
}



