.btn-contactus{
  width: 100%;
  color: white;
  margin-left: 10px;
  background-color: #D12316;
  border-radius: 4px;
  padding: 15px 0px !important;
  float: right;
  font-family: SF UI Display;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}
.btn-contactus:hover {
  color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
}
.footerRow {
  padding: 10px 10px;
  /* height: 7vh; */
  font-family: Roboto,sans-serif;
  
  background: #022e87;

}

.FooterSeparator{
  color: hsla(0,0%,100%,.6);
  padding: 0px 2px;
}
.spanFooterTextHeader {

  font-family: SF UI Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: white;
  display: inline-block;
}

.footerLinks{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
  display: inline;
  margin-left: 2px;
}
.footerSeparatorLinks{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: white;
  display: inline;
  margin-left: 2px;
}
.footerSpan{

  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
  display: inline-block;

}
.footerAddressSpan{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.3);
  display: inline-block;
}
.imageFooter1{
  
  content: url("../images/Footer_JV-Partner-Classifieds2.jpg");
  width: 100%;
}
.imageFooter2{
  content: url("../images/Footer_Special_Offers2.jpg");
  width: 100%;
}
.imageFooter3{
  content: url("../images/Footer_Affiliates2.jpg");
  width: 100%;
}
.imageFooter4{
  content: url("../images/Footer_social_icon_FB.png");
  width: 100%;
}
.imageFooter5{
  content: url("../images/Footer_social_icon_IG.png");
  width: 100%;
}
.imageFooter6{
  content: url("../images/Footer_social_icon_LinkedIn.png");
  width: 100%;
}
.imageFooter7{
  content: url("../images/Footer_social_icon_Twitter.png");
  width: 100%;
}
.imageFooter8{
  content: url("../images/Footer_social_icon_Youtube.png");
  width: 100%;
}
.imageFooter9{
  content: url("../images/Footer_social_icon_Pinterest.png");
  width: 100%;
}
.imageFooter10{
  content: url("../images/Footer_social_icon_SnapChat.png");
  width: 100%;
}
.imageFooter11{
  content: url("../images/Footer_Access_Granted2.jpg");
  width: 100%;
}

@media  (max-width: 768px) {

}
