

.categorySearchBackground {
  background-image: url("../images/othersBG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: hsla(0,0%,100%,.6);
    background-blend-mode: lighten;

}
.btnSponsored{
    background-color: #254D9F;
    color: white;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 7px 17px !important;
    transition: all 0.4s ease 0s;
    margin-left: 20px;
    margin-top: -10px;
}
.btnSponsored:hover {
   color: white !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57)!important;
  transition: all 0.4s ease 0s!important;
  }

.categorySearchPageTitle {
  font-family: Bebas Neue;
  font-style: normal;
  font-size: 56px;
  /* line-height: 80px; */
  font-weight: 100;
 
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-transform: capitalize;
}
.CategoryCard_Business2 {
  background-image: url("../images/CategoryCard_Business2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.CategoryCard_Life2 {
  background-image: url("../images/CategoryCard_Life2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.CategoryCard_Relationship2 {
  background-image: url("../images/CategoryCard_Relationship2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.CategoryCard_Health2 {
  background-image: url("../images/CategoryCard_Health2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.CategoryCard_Fitness2 {
  background-image: url("../images/CategoryCard_Fitness2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.CategoryCard_More2 {
  background-image: url("../images/CategoryCard_More2.png"), linear-gradient(rgba(11, 55, 144, 0.8), rgba(11, 55, 144, 0.2));
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.NoCoachesinthissection{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.05em;
  font-size: 18px;
  color: #222222;
}
.Nomoreresults{
  font-family: SF UI Display;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.05em;
  font-size: 14px;
  color: #222222;
}
.CategorySearchPageRow{
  padding-top: 15%;
}

@media  (max-width: 1024px) {
  
.categorySearchPageTitle {

  font-size: 30px;
}
.CategorySearchPageRow{
  padding-top: 50%;
}
}