.paddingOnLargeScreenOnly{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .imgArrowDown{
    
    content:url("../images/arrowdown_red.gif");
    height: 120px;
    
  }
 
  
  section#claimprofile-section {
    background-image: url("../images/othersBG.png");
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
    margin-top: 100px;
  }

  
  div.claimProfileImageContainer {
    height: auto;
    /* width: 480px; */
    background-image: url("../images/signUp_side_image.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  

  .ClaimProfileHeader{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
  
  
    color: #222222;
  }
  .ClaimProfileStatus{
    font-family: SF UI Display;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  
    color: #555555;
  }

  @media  (max-width: 1024px) {
  
    .ClaimProfileHeader{

        font-size: 20px;
    
      }
    .paddingOnLargeScreenOnly{
      padding-top: 20px;
      padding-bottom: 10px;
    }

    section#claimprofile-section {
      background-image:unset;
      padding: 0px 0px;
      
    }

    
 
    .hrNotVisibleOnSmallScreen{
      display: none;
    }
    .PaddingRemoveOnSmallScreen{
      padding-top: 0px;
    }
  }