div.signin_box {
  /* width: 440px; */
  font-family: 'Roboto', sans-serif;
}

div.img_SignIn {
  height: auto;
  /* width: 480px; */
  background-image: url("../images/signIn_side_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signInBtn {
  border-radius: 0;
}






